<template>
  <div
    ref="carImageEl"
    :class="[
      'car_image',
      {
        'car_image--new': isNew,
        'car_image--loaded': !loading,
        'car_image--error': error,
        'car_image--cutout': vehicleImage.backgroundClass
      },
      error && errorBackgroundClass
    ]"
  >
    <template v-if="!isInView"></template>
    <template v-else-if="vehicleImage.imageIsPlaceHolder">
      <NuxtImg
        ref="imgEl"
        fit="cover"
        :width="width"
        :height="height"
        :alt="`${vehicleImage.alt}`"
        :src="vehicleImage.url"
        :densities="densities"
        loading="lazy"
        @load="onload"
        @error="onerror"
      />
      <p v-if="!error">Images available soon</p>
    </template>
    <template v-else-if="isNew">
      <NuxtPicture
        ref="imgEl"
        provider="BBD"
        format="webp,png"
        :densities="densities"
        :sizes="sizes"
        :width="width"
        :height="height"
        loading="lazy"
        :img-attrs="{ class: error ? null : vehicleImage.backgroundClass }"
        :alt="vehicleImage.alt"
        :src="vehicleImage.url"
        @load="onload"
        @error="onerror"
      />
    </template>
    <template v-else>
      <NuxtImg
        ref="imgEl"
        fit="contain"
        :densities="densities"
        :width="width"
        :height="height"
        :alt="`${vehicleImage.alt}`"
        :src="vehicleImage.url"
        loading="lazy"
        @load="onload"
        @error="onerror"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { IResponsiveImage } from '~/types/image'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

export default {
  name: 'ImageCar',
  props: {
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: () => ({})
    },
    sizes: {
      type: String,
      required: false,
      default: 'xl:624px'
    },
    pov: {
      type: String,
      required: false,
      default: null
    },
    densities: {
      type: String,
      required: false,
      default: null
    },
    width: {
      type: Number,
      required: false,
      default: 624
    },
    height: {
      type: Number,
      required: false,
      default: 375
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    objectFitContain: {
      type: Boolean,
      required: false,
      default: null
    },
    useInteresctionObserver: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  emits: ['image-loaded', 'image-error'],

  setup(props, { emit }) {
    const isInView = ref(false)
    const loading = ref(true)
    const error = ref(false)
    const onload = () => {
      emit('image-loaded', true)
      loading.value = false
      error.value = false
    }

    const onerror = () => {
      emit('image-error', true)
      loading.value = false
      error.value = true
    }

    const { isNew, isSmart, isNightMode } = useVehicle(toRef(props, 'vehicle'))
    const imgEl = ref<HTMLImageElement | any | null>(null)
    const carImageEl = ref<HTMLImageElement | any | null>(null)
    onMounted(() => {
      // deals with issue when image loaded while hydrating
      if (imgEl.value) {
        const img =
          imgEl.value.$el.nodeName === 'IMG'
            ? imgEl.value.$el
            : imgEl.value.$el.getElementsByTagName('img')[0]

        if (img && img.complete) {
          if (img.naturalWidth === 0) {
            onerror()
          } else {
            onload()
          }
        }
      }
    })

    if (props.useInteresctionObserver) {
      // Load image when component in view
      const { stop } = useIntersectionObserver(
        carImageEl,
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            isInView.value = true
            stop()
          }
        },
        { threshold: 0.01 }
      )
    } else {
      isInView.value = true
    }

    // TODO : update interface and add return type here
    const vehicleImage = computed<IResponsiveImage>(() => {
      if (props.url) {
        return {
          url: props.url,
          pov: props.pov,
          alt: props.vehicle.Description,
          backgroundClass: imageBackgroundClass(
            props.vehicle.VehicleClass!,
            props.vehicle.BodyStyle!,
            isNightMode.value
          )
        } as IResponsiveImage
      }
      return getVehicleImage({
        url:
          props.url ||
          props.vehicle.ImageUrl ||
          props.vehicle.Media?.MainImageUrl ||
          '',
        isNightMode: isNightMode.value,
        vehicle: props.vehicle,
        isCropped: true,
        pov: props.pov,
        width: 624,
        height: 260
      })
    })

    const errorBackgroundClass = computed(() => {
      if (error.value) {
        return `os-image-fallback--${isNew.value ? 'new' : 'used'}-${
          isSmart.value ? 'smart' : 'car'
        }`
      }

      return isNew.value ? vehicleImage.value?.backgroundClass || '' : ''
    })

    return {
      vehicleImage,
      isNew,
      errorBackgroundClass,
      loading,
      onload,
      onerror,
      imgEl,
      error,
      isInView,
      carImageEl
    }
  }
}
</script>

<style lang="scss">
.car_image {
  background-color: $white;
  animation: 0.7s linear 0s infinite alternate imageLoadingBackgroundAnimation;
  position: relative;
  @include aspect-ratio(4, 3);

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    opacity: 0;
    transform: scale(1.07);
    transform-origin: center;
    transition-delay: 0.3s;
    transition:
      transform 0.7s $cubicEasing,
      opacity 1.7s $cubicEasing;
    min-width: 1px;
    min-height: 1px;
    @include aspect-ratio(4, 3);
  }

  &--new {
    @include aspect-ratio(40, 24);

    img {
      @include aspect-ratio(40, 24);
    }
  }

  &--loaded {
    animation-name: none;

    img {
      opacity: 1;
      transform: scale(1);
    }
    p {
      position: absolute;
      bottom: 5%;
      width: 100%;
      text-align: center;
      color: $white;
    }
  }

  &--error {
    animation-name: none;
    background-color: $grey--lightest;

    &::before {
      color: $black--darkest;
      content: 'Images available soon. ';
      position: absolute;
      font-size: rem(1);
      white-space: pre;
      display: inline;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -10px);
    }

    img {
      opacity: 0;
    }
  }
}
</style>
